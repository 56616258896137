import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const AddNews = createAsyncThunk(
  "blogs/getBlogList",
  async function ({newData}, { rejectWithValue }) {
    try {
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  newsList: [
    {
      id: 1,
      img: "https://www.keu.kg/uploads/news/TfNvDeKzYwA2Y_p_z_1y0lozY1qGyeAt.jpg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 2,
      img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 3,
      img: "https://www.keu.kg/uploads/news/TfNvDeKzYwA2Y_p_z_1y0lozY1qGyeAt.jpg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 4,
      img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 5,
      img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 6,
      img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 7,
      img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
    {
      id: 8,
      img: "https://www.keu.kg/uploads/news/hBGYuKq8QiJCK-cgScLQ7-3dgPX2YiyA.jpeg",
      title: "НИУ КЭУ -  Образование без границ!",
      subtitle: "Профориентационная работа от кафедры ФСиГН",
      date: "01.03.2024",
      text: [
        `28 февраля 2024 года сотрудники кафедры «Философии и социально-гуманитарных наук» к.соц.н., доцент Мамырканов М.А., методист Абас уулу Б., совместно со студентами 2 - курса группы ОСР-1-22 Дуйшенбиевой М., Бейшеналы кызы М. провели профориентационную работу в средней общеобразовательной школе № 41 города Бишкек.
  
        Профориентационная работа была проведена для школьников 9 «А», 9 «Б» и 11 «А» классов.
        
        Доцент Мамырканов М. А. выступая перед школьниками отметил, что НИУ КЭУ им. М. Рыскулбекова является одним из ведущих вузов по подготовке специалистов во всех сферах Кыргызской Республики, экономики, социальной работы, социальной психологии, туризма, IT-технологий, управления бизнесом, менеджмента. Занятия проводятся на государственном, русском, английском языках. Университет имеет современную инфраструктуру и имеет удобное месторасположение в центре города.
        
        В рамках профориентационной работы Абас уулу Бекжан остановился, что НИУ КЭУ им. М. Рыскулбекова проводит активную работу в сфере международного сотрудничества, предоставляя студентам уникальные возможности академической мобильности, имея партнерские отношения с вузами ближнего и дальнего зарубежья. Это открывает перед студентами перспективы для обучения за рубежом, расширяя горизонты и предоставляя возможность получить образование на мировом уровне. В университете учебный процесс отвечает современным образовательным стандартам, используются инновационные методики, квалифицированный преподавательский состав, что создает благоприятную образовательную среду для студентов и способствует успешной учебе.
        
        Дуйшенбиева Медина рассказала, что НИУ КЭУ им. М. Рыскулбекова предоставляет уникальную возможность студентам получить высшее образование по доступной цене, соответствующее требованиям мировых стандартов в области образования, бакалавриата, магистратуры, что позволяет проходить стажировки и практику в странах ближнего и дальнего зарубежья. Медина отметила, также об обучении с применением дистанционных технологий.
        
        Бейшеналы к Мирана отметила, что, обучаясь в университете, студенты могут получить не только актуальные знания, но и уникальные навыки, необходимые для успешной адаптации в меняющемся мире. В вузе студенты активно принимают участие в молодежных организациях. Университет формирует лидеров, готовых встречать вызовы будущего с уверенностью и получить реальное будущее.
        
        Встреча со школьниками прошла с восторгом и впечатлениями. Участники мероприятия были заинтересованы обучением в НИУ КЭУ им. М. Рыскулбекова, были собраны контакты для дальнейшего глубокого ознакомления с университетом в День открытых дверей.`,
      ],
      departament: "Кафедра «Философии и социально-гуманитарных наук»",
    },
  ],
  newsById: null,
  status: "",
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    getNewsList(state, action) {},
    getNewsById(state, action) {
      state.newsById = state.newsList.filter((newsObject) => {
        return newsObject.id === action.payload.id;
      });
    },
  },
});

export const { addNews, getNewsList, getNewsById } = newsSlice.actions;
export default newsSlice.reducer;
